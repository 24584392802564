import React from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import MediaModal from '../MediaModal'

import HtmlEditor from './HtmlEditor'

import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default class NewDiscussion extends React.Component {

	state={
		title: '',
		content: '',
		editorState: EditorState.createEmpty(),
    	open: false,		
    	medium: [],
    	value: '',
    	copied: false,
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false, copied: false, });
	};	

	onEditorStateChange: Function = (editorState) => {
		this.setState({
		  editorState,
		});
	};		

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleCreateDiscussion(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('title', this.state.title);
		data.append('content', draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));		
		axios
			.post(API_URL+'/discussions/save'+'?api_token='+localStorage.getItem('api_token') , data)
			.then(({data})=>{
				history.push('/discussions')
			});
	}

	handleCopy(e){
		this.setState({
			copied: true
		})
	}

	componentDidMount(){
		axios
			.get(API_URL+'/media'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					medium: data.media
				});
			})
	}

	render() {
		const { title,editorState,open,medium,copied } = this.state;
		return (
			<div>
				<MediaModal 
					open={open} 
					onClose={this.onCloseModal}
					copied={copied}
					medium={medium}
					handleCopy={this.handleCopy.bind(this)}
				/>

				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/discussions'>Discussions</Link></li>
				  <li className="breadcrumb-item active">Create new Discussions</li>
				</ol>				
				<div className="row justify-content-center">
					<div className="col-md-9">
						<form onSubmit={this.handleCreateDiscussion.bind(this)}>
						  <fieldset>
						    <legend>New Discussion</legend>

		        			<FontAwesomeIcon icon={faInfoCircle} /> Click <button className="btn btn-sm btn-success" type="button" onClick={this.onOpenModal}>Open media</button> to view media.
						    <p></p>
						    <div className="form-group">
						      <label>Title</label>
						      <input 
						      	name="title"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="titleHelp" 
						      	placeholder="Enter Title" 
						      	value={title}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>
						    
						    <div className="form-group">
						      <label>Content</label>
							  <HtmlEditor editorState={editorState} onEditorStateChange={this.onEditorStateChange} />
						    </div>	

						    <div className="form-group">
								<button className="btn btn-success" type="submit">Save</button>
						    </div>	

						  </fieldset>

						</form>			
					</div>
				</div>
			</div>
		);
	}
}
