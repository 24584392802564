import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class Lecture extends React.Component {
	state = {
		lecture_details: [],
		members: [],
		member_details:[],
		title: '',
		price: '',
		file_path: '',
		editMode: false,
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/lectures/fetch/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					lecture_details: data.post,
					title: data.post.title,
					price: data.post.price,
					file_path: data.post.file_path
				});
			})

		axios
			.get(`${API_URL}/lectures/getPurchasedDetails/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					members: data.post,
				});
			})			
	}

	checkProfile(member_id){
		axios
			.get(`${API_URL}index.php/Api/getProfile/`+member_id+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					member_details: data.post,
				});
			})			
	}

	handleMarkAsPaid(member_id){
		// console.log(member_id)
		const {history} = this.props
		axios
			.get(`${API_URL}/lectures/markAsPaid/`+member_id+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push('/lectures')
			})

	}

	handleFileChange(e){
		let file = e.target.files;
		this.setState({
			file_path: file[0]
		})
	}

	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}

	renderUserLists(members){
		// console.log(members)
		return members.map(item=>(
				<div className="list-group-item list-group-item-action" key={item.users[0].id}>{item.users[0].firstName +" "+ item.users[0].lastName} 
					{item.payment_status !== 'paid' ? 
						this.renderPaymentCondition(item)
						// <button onClick={()=>this.handleMarkAsPaid(item.users[0].user_id)} className="btn btn-success float-right">Mark as paid</button>
						:
						<span className="float-right">Paid</span>
					}
				</div>
			))
	}

	renderPaymentCondition(item){
		if(item.payment_method === 'bpi'){
			if(item.bpifilepath != null){
				return (
					<div>
						{this.renderMarkAsPaidBtn(item)}
						<a className="btn btn-primary btn-sm float-right" target="_blank" rel="noopener noreferrer" href={`${WEB_URL}${item.bpifilepath}`}>View Receipt</a>
					</div>
				)
			}else{
				return (
					<p className="btn btn-warning btn-sm float-right">No Receipt Yet</p>
				)				
			}
		}else{
			return(
				<div>
					
					{this.renderMarkAsPaidBtn(item)}
				</div>
				)

		}
	}

	renderMarkAsPaidBtn(item){
		return(
			<button onClick={()=>this.handleMarkAsPaid(item.user_id)} className="btn btn-success btn-sm float-right">Mark as paid</button>
		)
	}


	handleUpdateCommittee(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('title', this.state.title);
		data.append('price', this.state.price);
		data.append('file_path', this.state.file_path,this.state.file_path.name);
		axios
			.post(API_URL+'index.php/Api/updateLecture'+'?api_token='+localStorage.getItem('api_token') , data,{
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  })
			.then(({data})=>{
				history.push('/lectures')
			});		
	}		

	render() {
		const { title, editMode ,file_path, lecture_details,price,members} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/lectures'>Lectures</Link></li>
				  <li className="breadcrumb-item active">{lecture_details.title}</li>
				</ol>
				{!editMode ?
					<div>
						<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{lecture_details.title}<span>&nbsp;</span>
							<small>
								<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}>  Edit</button>
							</small>
						</h1>
						<a target="_blank" rel="noopener noreferrer" href={`${API_URL}index.php/Lectures/listoflectures`}>
							<small>
								 Visit Page
							</small>
						</a>
						<p></p>
						<table className="table table-hover">
							<tbody>
								<tr>
									<td>Title:</td>
									<td>{title}</td>
								</tr>
								<tr>
									<td>Uploaded file:</td>
									<td><a target="_blank" rel="noopener noreferrer" href={API_URL+file_path}>{file_path}</a></td>
								</tr>	
								<tr>
									<td>Lecture Price:</td>
									<td>{price}</td>
								</tr>	
								<tr>
									<td>Questionnaires:</td>
									<td><Link to={`${this.props.match.params.id}/questionnaires`}><FontAwesomeIcon icon={faSearch} /></Link></td>
								</tr>									
								<tr>
									<td>Members who purchased this lecture:</td>
									<td>
										<div className="list-group">
											{this.renderUserLists(members)}

										</div>									
									</td>
								</tr>																							
							</tbody>
						</table>
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateCommittee.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
						  <fieldset>
						    <legend>Edit Lecture</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Title</label>
						      <input 
						      	name="title"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={title}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>
		                  <div className="form-group files">
		                    <label>Upload new title </label>
		                    <input 
		                    	type="file" 
		                    	name="display_picture" 
		                    	size="20" 
		                    	accept=".pdf, .docx, .doc" 
		                    	className="form-control form-control-lg"
		                    	onChange={this.handleFileChange.bind(this)}
		                    />
		                  </div>	

						    <p></p>
						    <div className="form-group">
						      <label>Price</label>
						      <input 
						      	name="price"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={price}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>

						    <div className="form-group">
								<button className="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Update</button><span>&nbsp;</span>
								<div className="btn btn-danger" onClick={this.handleCancelEditClick.bind(this)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</div>
						    </div>	
						  </fieldset>
						</form>
					</div>
					}
			</div>
		);
	}
}
