import React from 'react';
import axios from 'axios';
import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		boards:[]
	}

	componentDidMount(){
		axios
			.get(API_URL+'/leadership/boardofgovernors'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					boards: data.boards
				});
			})
	}

	render() {
		const { boards } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Board of Governors</li>
				</ol>			
				<Link to='boardOfGovernors/create' className="btn btn-success">Create new</Link>
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{boards && 
								boards.map(board=>(
									<Link to={`boardOfGovernors/${board.id}`} className="list-group-item list-group-item-action" key={board.id}>
								    	<p className="m-0">{board.name} </p>
								    	<p className="m-0">{board.term}</p>
									</Link>
								))
							}
						</div>	
					</div>

					<div className="col-md-8">
						<div className="jumbotron">
						  <h1 className="display-3">Board of Governors</h1>
						  <p className="lead">Here are the list of governors in the PSP Website</p>
						  <hr className="my-4" />
						  <p>To add new governor, click create new. Click any of the governor listed on the left panel to view the details</p>

						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
