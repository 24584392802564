import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class Lecture extends React.Component {
	state = {
		lecture_details: [],
		questions: [],
		member_details:[],
		title: '',
		price: '',
		file_path: '',
		editMode: false,
	}

	componentDidMount(){
		axios
			.get(`${API_URL}index.php/Api/viewsinglelecture/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					lecture_details: data.post,
					title: data.post.title,
					price: data.post.price,
					file_path: data.post.file_path
				});
			})

		axios
			.get(`${API_URL}index.php/Lectures/getQuestionsWithChoices/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					questions: data.questions,
				});
			})			
	}

	checkProfile(member_id){
		axios
			.get(`${API_URL}index.php/Api/getProfile/`+member_id+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					member_details: data.post,
				});
			})			
	}

	handleMarkAsPaid(member_id){
		const {history} = this.props
		axios
			.get(`${API_URL}index.php/Api/markAsPaid/`+member_id+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push('/lectures')
			})

	}

	handleFileChange(e){
		let file = e.target.files;
		this.setState({
			file_path: file[0]
		})
	}

	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}


	handleUpdateCommittee(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('title', this.state.title);
		data.append('price', this.state.price);
		data.append('file_path', this.state.file_path,this.state.file_path.name);
		axios
			.post(API_URL+'index.php/Api/updateLecture'+'?api_token='+localStorage.getItem('api_token') , data,{
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  })
			.then(({data})=>{
				history.push('/lectures')
			});		
	}		

	render() {
		const { title, editMode ,file_path, lecture_details,price,members,member_details,questions} = this.state;
		console.log(questions)
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/lectures'>Lectures</Link></li>
				  <li className="breadcrumb-item"><Link to={`/lectures/${this.props.match.params.id}`}>{lecture_details.title}</Link></li>
				  <li className="breadcrumb-item active">Questionnaires</li>
				</ol>
				<div>
					<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{`${lecture_details.title}Questionnaires`}<span>&nbsp;</span> 
						<small>
							<button className="btn btn-sm btn-success" onClick={this.handleEditClick.bind(this)}><FontAwesomeIcon icon={faPlus} /> Add new</button>
						</small>
					</h1>
					<p></p>
					<table className="table table-hover">
						<thead className="table-primary">
							<tr>
								<th>Questions</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Uploaded file:</td>
								<td><a target="_blank" rel="noopener noreferrer" href={API_URL+file_path}>{file_path}</a></td>
							</tr>	
							<tr>
								<td>Lecture Price:</td>
								<td>{price}</td>
							</tr>	
							<tr>
								<td>Questionnaires:</td>
							</tr>									
							<tr>
								<td>Members who purchased this lecture:</td>
								<td>
									<div className="list-group">
										{members && members.map(item=>(
											<div className="list-group-item list-group-item-action" key={item.id}>{item.firstName} 
												{item.payment_status !== 'paid' ? 
													<button onClick={()=>this.handleMarkAsPaid(item.member_id)} className="btn btn-success float-right">Mark as paid</button>
													:
													<span className="float-right">Paid</span>
												}
											</div>
										))}
									</div>
								</td>
							</tr>
							{questions ?
								questions.map(question=>(
									<tr>
										<td>{question.question}</td>
										<td><Link className="btn btn-sm btn-success btn-round" to={`/lectures/${this.props.match.params.id}/questionnaires/${question.id}`}>Edit | View</Link></td>
									</tr>
								))
								:
									<tr>
										<td colSpan='2'>N/A</td>
									</tr>								
							}											
						</tbody>
					</table>
				</div>				
			</div>
		);
	}
}
