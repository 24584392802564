import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { API_URL } from '../utils/apiUrl'

export default class MemberDetailsPrint extends React.Component {

	state={
		firstName: '',
		lastName: '',
		emailAddress: '',
		member_details: [],
		editMode : false,
		errors:''
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/members/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					member_details: data.member_details,
					firstName: data.member_details.firstName,
					lastName: data.member_details.lastName,
					emailAddress: data.member_details.emailAddress,
					username: data.member_details.username,
				});
			})		
	}

	handleEditButtonClicked(){
		this.setState({
			editMode: !this.state.editMode
		})
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})	
	}

	handleUpdateButtonClicked(e){
		const {history} = this.props
		e.preventDefault();
		let fd = new FormData();
		fd.append('id', this.props.match.params.id);
		fd.append('firstName', this.state.firstName);
		fd.append('lastName', this.state.lastName);

		axios
			.post(API_URL+'/members/update'+'?api_token='+localStorage.getItem('api_token'),fd)
			.then(({data})=>{
				if(data.status === 'failed'){
					this.setState({
						errors: data.field
					})
				}else{
					this.setState({
						editMode: !this.state.editMode
					})					
				}
			})

	}

	handleCancelButtonClicked(){
		this.setState({
			editMode: !this.state.editMode,
			firstName: this.state.member_details.firstName,
			lastName: this.state.member_details.lastName,
		})
	}

	render() {
		const {member_details,editMode, firstName, lastName, emailAddress, username, errors} = this.state;
		return (
			<div style={{marginLeft: '100px', marginRight: '100px'}}>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/members'>Members</Link></li>
				  <li className="breadcrumb-item active">{member_details.firstName}</li>
				</ol>			
				<div className="row justify-content-center">
					<div className="col-md-7">
						<table className="table table-striped table-inverse table-hover" >
							{!editMode ? 
								<tbody>
									<tr>
										<td>Name</td>
										<td>{firstName+" "+lastName}</td>
									</tr>
									<tr>
										<td>Email address</td>
										<td>{emailAddress ? emailAddress : 'N/A'}</td>
									</tr>
									<tr>
										<td>Username</td>
										<td>{username}</td>																		
									</tr>
								</tbody>
								:
								<tbody>
									<tr>
										<td colSpan={2}>
										    <div className="form-group">
										      <label>Name</label>
										      <div className="row">
										      	<div className="col">
										      		<label >First Name: </label>
										      		<input 
										      			name="firstName"
										      			type="text" 
										      			value={firstName} 
										      			className="form-control" 
										      			placeholder="Enter first name" 
										      			onChange={this.handleInputChange.bind(this)}
										      		/>
										      	</div>
										      	<div className="col">
										      		<label >Last Name: </label>
										      		<input 
										      			name="lastName"
										      			type="text" 
										      			value={lastName} 
										      			className="form-control" 
										      			placeholder="Enter last name" 
										      			onChange={this.handleInputChange.bind(this)}
									      			/>

										      	</div>										      	
										      </div>
										    </div>											
										</td>
									</tr>
																									
								</tbody>								
							}
						</table>			
						<hr/>
						{!editMode ? 
							<div>
								<button  className="btn btn-info btn-round" onClick={this.handleEditButtonClicked.bind(this)}>Edit</button>
								<button className="btn btn-danger btn-round float-right" onClick={()=>{this.props.history.push('/members')}}>Back to list</button>
							</div>
							:
							<div>
								<button className="btn btn-success btn-round" onClick={this.handleUpdateButtonClicked.bind(this)}>Update</button>
								<button className="btn btn-seconday btn-round float-right" onClick={this.handleCancelButtonClicked.bind(this)}>Cancel</button>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}
