import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		accredited:[]
	}

	handleImageUrlSelected(e){
		alert(e.target.src)
	}

	componentDidMount(){
		axios
			.get(API_URL+'/accreditation/all?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					accredited: data.accredited
				});
			})
	}

	render() {
		const { accredited } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Accreditation</li>
				</ol>			
				<Link to='accreditation/create' className="btn btn-success">Create new</Link>
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{accredited && accredited.map(item=>(
								<Link to={`accreditation/${item.id}`} className="list-group-item list-group-item-action" key={item.id}>
							    	{item.hospital_name}
								</Link>
							))}

						</div>	
					</div>

					<div className="col-md-8">
						<div className="jumbotron">
						  <h1 className="display-3">Accreditation and Institutions</h1>
						  <p className="lead">Here are the list of Accredited institutions in the PSP Website</p>
						  <hr className="my-4" />
						  <p>To add new accreditation, click create new. Click any of the forms listed on the left pannel to view the details</p>	

						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
