import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default class Board extends React.Component {
	state = {
		region_details: [],
		name: '',
		number:'',
		leaders:[],
		editMode: false,
	}

	componentDidMount(){

		axios
			.get(`${API_URL}/leadership/regions/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					region_details: data.post,
					name: data.post.name,
					number: data.post.number,
					leaders: data.post.leaders,
				});
			})
	}


	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}

	handleDeleteClick(){
		const {history} = this.props
		axios
			.get(API_URL+`/leadership/regionalchapter/${this.props.match.params.id}/delete`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push('/regions')
			})
	}

	handleUpdateBop(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('name', this.state.name);
		// data.append('region', this.state.region);
		data.append('number', this.state.number);
		axios
			.post(API_URL+'/leadership/updateRegion'+'?api_token='+localStorage.getItem('api_token') , data,{
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  })
			.then(({data})=>{
				history.push('/regions')
			});		
	}		

	render() {
		const { name, editMode ,number,  region_details,leaders} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/regions'>Regional Chapter</Link></li>
				  <li className="breadcrumb-item active">{region_details.name}</li>
				</ol>
				{!editMode ?
					<div>
						<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{region_details.name}<span>&nbsp;</span>
							<small>
								<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}>  Edit</button>
							</small>
							<small>
								<button className="btn btn-sm btn-danger" onClick={this.handleDeleteClick.bind(this)}>  Delete</button>
							</small>							
						</h1>
						<a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/leadership/regionalchapters`}>
							<small>
								 Visit Page
							</small>
						</a>
						<p></p>
						<div className="row">
							<div className="col-5">
								<table className="table table-hover">
									<tbody>
										<tr>
											<td>Region Designation:</td>
											<td>{name}</td>
										</tr>
										<tr>
											<td>Region Number:</td>
											<td>{number}</td>
										</tr>								
									</tbody>
								</table>
							</div>
							<div className="col-7">
								<div className="card">
									<div className="card-body">
										<h4 className="card-title">Leaders</h4>
										<Link to={`/regions/${this.props.match.params.id}/createLeader`} className="btn btn-success">Create new Region {number} Leader</Link>
										<p></p>
										<table className="table table-hover table-inverse">
											<thead>
												<tr>
													<th className="w-25">Name</th>
													<th className="w-25">Position</th>
													<th className="w-25">Ordering Position</th>
													<th className="w-25">Action</th>
												</tr>
											</thead>
											<tbody>
												{leaders &&
													leaders.map(leader=>(
														<tr key={leader.id}>
															
															<td>{leader.name}</td>
															<td>{leader.position}</td>
															<td>{leader.row_position}</td>
															<td>
																<Link to={`/regionalLeaders/${leader.id}`} className="btn btn-primary" >
															    	View
																</Link>
															</td>
															
														</tr>
													))
												}											
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateBop.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
						  <fieldset>
						    <legend>Edit Regional Leader</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Region Designation</label>
						      <input 
						      	name="name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={name}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>					    						    
						    <div className="form-group">
						      <label>Region Number</label>
						      <input 
						      	name="number"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={number}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>
						    
						    <div className="form-group">
								<button className="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Update</button><span>&nbsp;</span>
								<div className="btn btn-danger"  onClick={this.handleCancelEditClick.bind(this)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</div>
						    </div>	
						  </fieldset>
						</form>
					</div>
					}
			</div>
		);
	}
}
