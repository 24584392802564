import React from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

export default class NewBoard extends React.Component {

	state={
		number:'',
		name:'',
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleCreateBoard(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('name', this.state.name);
		data.append('number', this.state.number);
		axios	
			.post(API_URL+'/leadership/saveNewRegion'+'?api_token='+localStorage.getItem('api_token') , data,  {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  }).then(({data})=>{
				history.push('/regions')
			});
	}

	render() {
		const { name,number } = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/regions'>Regional Chapter</Link></li>
				  <li className="breadcrumb-item active">Create new Region</li>
				</ol>				
				<div className="row justify-content-center">
					<div className="col-md-9">
						<form onSubmit={this.handleCreateBoard.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
						  <fieldset>
						    <legend>New Regions</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Region designation</label>
						      <input 
						      	name="name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Ilocos Region" 
						      	value={name}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>
						    <div className="form-group">
						      <label>Region number</label>
						      <input 
						      	name="number"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="positionHelp" 
						      	placeholder="1" 
						      	value={number}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>
						    <div className="form-group">
								<button className="btn btn-success" type="submit">Save</button>
						    </div>	

						  </fieldset>

						</form>			
					</div>
				</div>
			</div>
		);
	}
}
