import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		members:[],
		searchInput: ''
	}

	componentDidMount(){
		
	}

	handleSearch(e){
		e.preventDefault();
		axios
			.get(API_URL+`/users/searchUser/${this.state.searchInput}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					members: data.users
				});
			})
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}


	render() {
		const { members,searchInput } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Members</li>
				</ol>	
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="jumbotron">
						  <h1 className="display-3">List of Members</h1>
						  <p className="lead">Here are the list of Members registered in the PSP Website</p>
						  <hr className="my-4" />
						  <Link to='Members/create' className="btn btn-success">Add new</Link>
						</div>						
					</div>
					<div className="col-md-8">
					  <form onSubmit={this.handleSearch.bind(this)}>
					  	<input 
					  		type="text" 
					  		className="form-control form-control-lg" 
					  		placeholder="Search" 
					  		aria-label="Username" 
					  		aria-describedby="basic-addon1" 
					  		onChange={this.handleInputChange.bind(this)}
					  		value={searchInput}
					  		name="searchInput"
					  	/>
					  </form>
					  <br/>					
						<table className="table table-hover table-striped">
						  <thead className=" table-info">
						    <tr>
						      <th scope="col">#</th>
						      <th scope="col">Name</th>
						      <th scope="col">Email Address</th>
						      <th scope="col">Action</th>
						    </tr>
						  </thead>
						  <tbody>
							{members && members.map((item,index)=>(
							    <tr key={item.id}>
							      <th scope="row">{index+1}</th>
							      <td>{item.firstName+" "+item.lastName}</td>
							      <td>{item.emailAddress}</td>
							      <td><Link to={`members/${item.id}/show`} className="btn btn-success btn-round">View</Link></td>
							    </tr>
							))}  
						  </tbody>
						</table> 						
					</div>
				</div>			
			</div>
		);
	}
}
