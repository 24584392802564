import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { WEB_URL } from '../utils/webUrl';

export default class SingleForm extends React.Component {
  state = {
    form_details: [],
    title: '',
    file_path: '',
    editMode: false,
  };

  componentDidMount() {
    axios
      .get(
        `${API_URL}/downloadables/${this.props.match.params.id}` +
          '?api_token=' +
          localStorage.getItem('api_token')
      )
      .then(({ data }) => {
        this.setState({
          form_details: data.post,
          title: data.post.title,
          file_path: data.post.file_path,
        });
      });
  }

  handleFileChange(e) {
    let file = e.target.files;
    this.setState({
      file_path: file[0],
    });
  }

  handleEditClick() {
    this.setState({
      editMode: !this.state.editMode,
    });
  }

  handleCancelEditClick() {
    this.setState({
      editMode: !this.state.editMode,
    });
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleUpdateCommittee(e) {
    const { history } = this.props;
    e.preventDefault();

    let fd = new FormData();
    fd.append('_method', 'PUT');
    fd.append('id', this.props.match.params.id);
    fd.append('title', this.state.title);
    if (this.state.file_path)
      fd.append('file_path', this.state.file_path, this.state.file_path.name);

    console.log(fd);
    axios
      .post(
        API_URL +
          '/downloadables/' +
          this.props.match.params.id +
          '/update?api_token=' +
          localStorage.getItem('api_token'),
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Type': 'application/json',
          },
        }
      )
      .then(({ data }) => {
        history.push('/forms');
      });
  }

  render() {
    const { title, editMode, file_path, form_details } = this.state;
    return (
      <div>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <Link to='/'>Dashboard</Link>
          </li>
          <li className='breadcrumb-item'>
            <Link to='/forms'>Forms</Link>
          </li>
          <li className='breadcrumb-item active'>{form_details.title}</li>
        </ol>
        {!editMode ? (
          <div>
            <h1
              className='titlehead'
              style={{ color: '#327d16', fontSize: 20, paddingTop: 30 }}
            >
              {form_details.title}
              <span>&nbsp;</span>
              <small>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={this.handleEditClick.bind(this)}
                >
                  {' '}
                  Edit
                </button>
              </small>
            </h1>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={`${API_URL}index.php/Forms/listofforms`}
            >
              <small>Visit Page</small>
            </a>
            <p />
            <table className='table table-hover'>
              <tbody>
                <tr>
                  <td>Title:</td>
                  <td>{title}</td>
                </tr>
                <tr>
                  <td>Uploaded file:</td>
                  <td>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`${WEB_URL}/storage/${form_details.path}`}
                    >
                      {form_details.title}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <form
              onSubmit={this.handleUpdateCommittee.bind(this)}
              encType='multipart/form-data'
              method='post'
              accept-charset='utf-8'
            >
              <fieldset>
                <legend>Edit Downloadable Form</legend>

                <p />
                <div className='form-group'>
                  <label>Title</label>
                  <input
                    name='title'
                    type='text'
                    className='form-control form-control-lg'
                    value={title}
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
                <div className='form-group files'>
                  <label>Upload new form </label>
                  <input
                    type='file'
                    name='display_picture'
                    size='20'
                    accept='.pdf, .docx, .doc'
                    className='form-control form-control-lg'
                    onChange={this.handleFileChange.bind(this)}
                  />
                </div>
                <div className='form-group'>
                  <button className='btn btn-success'>
                    <FontAwesomeIcon icon={faCheckCircle} /> Update
                  </button>
                  <span>&nbsp;</span>
                  <div
                    className='btn btn-danger'
                    onClick={this.handleCancelEditClick.bind(this)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> Cancel
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        )}
      </div>
    );
  }
}
