import React from 'react';
import {Link } from 'react-router-dom';

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faTape } from '@fortawesome/free-solid-svg-icons'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

export default class Home extends React.Component {
	render() {
		return (
			<div className=" py-5">
				<div className="row">
					<div className="col">
						<h1 className="text-info">PSP CMS Dashboard </h1>
						<p><small>Click the any button below.</small> <button className="btn btn-danger float-right" onClick={this.props.handleLogout}><FontAwesomeIcon icon={faSignOutAlt} />Logout</button></p>
						
					</div>
				</div>
				<hr/>
			    <div className="row align-content-center">
					<div className="col mx-auto" >
			        	<Link to='Banner'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faImage}></FontAwesomeIcon> Promoting Banner</h4>
				            </div>
			        	</Link>
			        </div>
					<div className="col mx-auto" >
			        	<Link to='Video'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faVideo}></FontAwesomeIcon> Promoting Video</h4>
				            </div>
			        	</Link>
			        </div>					        			    
					<div className="col mx-auto" >
			        	<Link to='Members'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> Members</h4>
				            </div>
			        	</Link>
			        </div>					        				        		        
			    </div>				
			    <div className="row align-content-center">
					<div className="col mx-auto" >
			        	<Link to='executiveOfficers'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faBookmark}></FontAwesomeIcon> Executive Officers</h4>
				            </div>
			        	</Link>
			        </div>		
					<div className="col mx-auto" >
			        	<Link to='boardOfGovernors'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faBookmark}></FontAwesomeIcon> Board of Governors</h4>
				            </div>
			        	</Link>
			        </div>					        		        
			        <div className="col mx-auto" >
			        	<Link to='boardOfPathology'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faBookmark}></FontAwesomeIcon> Board of Pathology</h4>
				            </div>
			        	</Link>
			        </div>			        	
			        <div className="col mx-auto" >
			        	<Link to='regions'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faBookmark}></FontAwesomeIcon> Regional Chapter</h4>
				            </div>
			        	</Link>
			        </div>			        				        		        
			    </div>
			    <div className="row align-content-center">
			        <div className="col mx-auto" >
			        	<Link to='news'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faNewspaper}></FontAwesomeIcon> News</h4>
				            </div>
			        	</Link>
			        </div>	
			        <div className="col mx-auto" >
			        	<Link to='media'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faTape}></FontAwesomeIcon> Media</h4>
				            </div>
			        	</Link>
			        </div>
			        <div className="col mx-auto" >
			        	<Link to='forms'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faFileDownload}></FontAwesomeIcon> Downloadable Forms</h4>
				            </div>
			        	</Link>
			        </div>			    			        
			        <div className="col mx-auto" >
			        	<Link to='lectures'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon> Lectures</h4>
				            </div>
			        	</Link>
			        </div>			        
			    </div>
			    <div className="row align-content-center">
			        <div className="col mx-auto" >
			        	<Link to='councils'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon> Council and Committees</h4>
				            </div>
			        	</Link>
			        </div>	
			        <div className="col mx-auto" >
			        	<Link to='discussions'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faComments}></FontAwesomeIcon>Discussions</h4>
				            </div>
			        	</Link>
			        </div>
			        <div className="col mx-auto" >
			        	<Link to='accreditation'>
				            <div className="jumbotron" >
				                <h4><FontAwesomeIcon icon={faAddressBook}></FontAwesomeIcon>Accreditation & Institutions</h4>
				            </div>
			        	</Link>
			        </div>			        				        			    
			    </div>
			</div>
		);
	}
}
