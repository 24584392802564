import React from 'react';
import axios from 'axios';
import ErrorAlert from '../ErrorAlert'

import { WEB_URL } from '../utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

export default class Home extends React.Component {
	state = {
		username: "",
		password: '',
		hasError: false
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	async handleLoginSubmit(e){
		const { history } = this.props
		e.preventDefault();
		let fd = new FormData();
		fd.append('username', this.state.username);		
		fd.append('password', this.state.password);		
		try{
			let {data} = await axios.post(WEB_URL+'/cms/login',fd)
			if(data){

				this.props.handleLoginSuccess(data.user);
				history.push('/')
			}else{

				this.setState({
					hasError: true
				});
			}
			
		}catch(error){
			// console.log(error)
			this.setState({
				hasError: true
			});
		}
	}

	render() {
		const {username,password,hasError} = this.state
		return (
		    <div className="row justify-content-center">

		    	<div className="col-5">
		    		{hasError && <ErrorAlert message="Invalid credentials!"/> }
			    	<h1 className="text-center">Login</h1>
			    	<p  className="text-center text-muted"><small>Please supply the inputs with correct credentials.</small></p>
					<div className="card bg-light mb-3">
					  <div className="card-body">
						<form onSubmit={this.handleLoginSubmit.bind(this)}>
						  <fieldset>
						    <div className="form-group">
						      <label>Username</label>
						      <input 
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	id="usernameHelp" 
						      	aria-describedby="usernameHelp" 
						      	placeholder="Enter username" 
						      	name="username"
						      	value={username}
						      	onChange={this.handleInputChange.bind(this)}
						      	autoFocus
						      />
						    </div>
						    <div className="form-group">
						      <label>Password</label>
						      <input 
						      	type="password" 
						      	className="form-control form-control-lg" 
						      	id="passwordHelp"
						      	placeholder="Enter password" 
						      	name="password"
						      	value={password}
						      	onChange={this.handleInputChange.bind(this)}				      	
						      />
						    </div>
						    <div className="form-group">
						    	<button className="btn btn-primary"><FontAwesomeIcon icon={faSignInAlt} /> Login</button>
						    </div>
						  </fieldset>
						</form>	
					  </div>
					</div>    	
		    	</div>
		    </div>
		);
	}
}
