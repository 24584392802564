import React from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

export default class NewMember extends React.Component {

	state={
		firstName: '',
		lastName: '',
		email: '',
		username: '',
		errors:[]
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleSubmit(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('firstName', this.state.firstName);
		data.append('lastName', this.state.lastName);
		data.append('email', this.state.email);
		data.append('username', this.state.username);
		axios
			.post(API_URL+'/users/save'+'?api_token='+localStorage.getItem('api_token') , data)
			.then(({data})=>{
				if(data.status){
					history.push('/members')
				}
				this.setState({
					errors: data.errors
				})
			})
	}



	render() {
		const { firstName, lastName, email, username,errors} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/members'>Members</Link></li>
				  <li className="breadcrumb-item active">New Member</li>
				</ol>				
				<div className="row justify-content-center">
					<div className="col-md-9">
						<form onSubmit={this.handleSubmit.bind(this)}>
						  <fieldset>
						    <legend>New Member</legend>

						    <p></p>
						    <div className="form-group">
						      <label>First Name</label>
						      <input 
						      	name="firstName"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	placeholder="Enter first name" 
						      	value={firstName}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>

						    <div className="form-group">
						      <label>Last Name</label>
						      <input 
						      	name="lastName"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	placeholder="Enter last name" 
						      	value={lastName}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>			

						    <div className="form-group">
						      <label>Email</label>
						      <input 
						      	name="email"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	placeholder="Enter email" 
						      	value={email}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						      {errors.email ? <p className="text-danger">{errors.email}</p> : ''}
						    </div>		

						    <div className="form-group">
						      <label>Username</label>
						      <input 
						      	name="username"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	placeholder="Enter username" 
						      	value={username}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    	{errors.username ? <p className="text-danger">{errors.username}</p> : ''}
						    </div>		

						    <div className="form-group">
								<button className="btn btn-success" type="submit">Save</button>
						    </div>	
                  		    

						  </fieldset>

						</form>			
					</div>
				</div>
			</div>
		);
	}
}
