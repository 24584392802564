import React from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

export default class NewForm extends React.Component {

	state={
		hospital_name:'',
		year_accredited: '',
		year:''
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}


	handleSubmit(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('hospital_name', this.state.hospital_name);
		data.append('year_accredited', this.state.year_accredited);
		data.append('year', this.state.year);
		axios
			.post(API_URL+'/accreditation/saveNewAccredited?api_token='+localStorage.getItem('api_token') , data)
			.then(({data})=>{
				history.push('/accreditation')
			});
	}



	render() {
		const { hospital_name, year_accredited, year} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/accreditation'>Accreditation</Link></li>
				  <li className="breadcrumb-item active">New Accredited</li>
				</ol>				
				<div className="row justify-content-center">
					<div className="col-md-9">
						<form onSubmit={this.handleSubmit.bind(this)} method="post" >
						  <fieldset>
						    <legend>New Accredited</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Hospital Name</label>
						      <input 
						      	name="hospital_name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Enter Hospital name" 
						      	value={hospital_name}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>

						    <div className="form-group">
						      <label>Year accredited</label>
						      <input 
						      	name="year_accredited"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Ex. 2018-2019" 
						      	value={year_accredited}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>						    

						    <div className="form-group">
						      <label>Year</label>
						      <input 
						      	name="year"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Ex. 2019" 
						      	value={year}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>		


						    <div className="form-group">
								<button className="btn btn-success" type="submit">Save</button>
						    </div>	

						  </fieldset>

						</form>			
					</div>
				</div>
			</div>
		);
	}
}
