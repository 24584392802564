import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		committees:[]
	}

	componentDidMount(){
		axios
			.get(API_URL+'/committees/all'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					committees: data.committees
				});
			})
	}

	render() {
		const { committees } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Council and Committees</li>
				</ol>			
				<Link to='committees/create' className="btn btn-success">Create new</Link>
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{committees && committees.map(committee=>(
								<Link to={`committees/${committee.id}`} className="list-group-item list-group-item-action" key={committee.id}>
							    	{committee.name}
								</Link>
							))}
						</div>	
					</div>

					<div className="col-md-8">
						<div className="jumbotron">
						  <h1 className="display-3">Council and Committees Pages</h1>
						  <p className="lead">Here are the list of committees in the PSP Website</p>
						  <hr className="my-4" />
						  <p>To add new Committees Page, click create new. Click any of the committees listed on the left pannel to view the details</p>

						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
