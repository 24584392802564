import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl';

import { Link } from 'react-router-dom';

export default class Home extends React.Component {
  state = {
    forms: [],
  };

  handleImageUrlSelected(e) {
    alert(e.target.src);
  }

  componentDidMount() {
    axios
      .get(
        API_URL +
          '/downloadables' +
          '?api_token=' +
          localStorage.getItem('api_token')
      )
      .then(({ data }) => {
        this.setState({
          forms: data.forms,
        });
      });
  }

  render() {
    const { forms } = this.state;
    return (
      <div>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <Link to='/'>Dashboard</Link>
          </li>
          <li className='breadcrumb-item active'>Forms</li>
        </ol>
        <Link to='forms/create' className='btn btn-success'>
          Create new
        </Link>
        <p />
        <div className='row'>
          <div className='col-md-4'>
            <div className='list-group'>
              {forms &&
                forms.map(item => (
                  <Link
                    to={`forms/${item.id}`}
                    className='list-group-item list-group-item-action'
                    key={item.id}
                  >
                    {item.title}
                  </Link>
                ))}
            </div>
          </div>

          <div className='col-md-8'>
            <div className='jumbotron'>
              <h1 className='display-3'>Downloadable Form</h1>
              <p className='lead'>
                Here are the list of Downloadable Forms in the PSP Website
              </p>
              <hr className='my-4' />
              <p>
                To add new Form, click create new. Click any of the forms listed
                on the left pannel to view the details
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
