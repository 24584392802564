import React from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

export default class NewLecture extends React.Component {

	state={
		title:'',
		price:'',
		file_path: null,
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleFileChange(e){
		this.setState({
			file_path: null
		})		
		let file = e.target.files;
		if(file.length > 0){
			this.setState({
				file_path: e.target.files[0]
			})			
			// if(file[0].type === 'video/mp4' || file[0].type === 'video/m4v' || file[0].type === 'video/avi' || file[0].type === 'video/mpg'){
			// 	this.setState({
			// 		file_path: file[0]
			// 	})
			// }
		}
	}

	handleUploadLecture(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('title', this.state.title);
		data.append('price', this.state.price);
		data.append('image', this.state.file_path);
		axios
			.post(API_URL+'/lectures/save'+'?api_token='+localStorage.getItem('api_token') , data,  {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  }).then(({data})=>{
				history.push('/lectures')
			});
	}



	render() {
		const { title, price, file_path} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/lectures'>Lectures</Link></li>
				  <li className="breadcrumb-item active">Upload new Lecture</li>
				</ol>				
				<div className="row justify-content-center">
					<div className="col-md-9">
						<form onSubmit={this.handleUploadLecture.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
						  <fieldset>
						    <legend>New Lecture</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Lecture Title</label>
						      <input 
						      	name="title"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Enter lecture title" 
						      	value={title}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>

		                  <div className="form-group files">
		                    <label>Upload lecture file </label>
		                    <input 
		                    	type="file" 
		                    	size="20" 
		                    	// accept=".mp4, .x-m4v, video/*"
		                    	className="form-control form-control-lg"
		                    	onChange={this.handleFileChange.bind(this)}
		                    	required 
		                    />
		                  </div>				

						    <p></p>
						    <div className="form-group">
						      <label>Lecture Price</label>
						      <input 
						      	name="price"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="priceHelp" 
						      	placeholder="Enter lecture price" 
						      	value={price}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>
						    {file_path != null &&

							    <div className="form-group">
									<button className="btn btn-success" type="submit">Save</button>
							    </div>	
						    }		                  		    

						  </fieldset>

						</form>			
					</div>
				</div>
			</div>
		);
	}
}
