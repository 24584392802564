import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		regions:[]
	}

	componentDidMount(){
		axios
			.get(API_URL+'/leadership/regionalchapter'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					regions: data.regions
				});
			})
	}

	render() {
		const { regions } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Regional Chapter</li>
				</ol>			
				<Link to='regions/create' className="btn btn-success">Create new Region</Link>
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{regions &&
								regions.map(region=>(
									<Link to={{ pathname: `regions/${region.id}`, state: { foo: 'bar'} }} className="list-group-item list-group-item-action" key={region.id}>
								    	{region.name}
									</Link>
								))
							}
						</div>	
					</div>

					<div className="col-md-8">
						<div className="jumbotron">
						  <h1 className="display-3">Regional Chapter</h1>
						  <p className="lead">Here are the list of regional chapter in the PSP Website</p>
						  <hr className="my-4" />
						  <p>To add new Leader, click create new. Click any of the leader listed on the left panel to view the details</p>

						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
