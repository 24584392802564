import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		officers:[]
	}

	componentDidMount(){
		axios
			.get(API_URL+'/leadership/executiveOfficer'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					officers: data.officers
				});
			})
	}

	render() {
		const { officers } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Executive Officers</li>
				</ol>			
				<Link to='executiveOfficers/create' className="btn btn-success">Create new</Link>
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{officers &&
								officers.map(officer=>(
									<Link to={`executiveOfficers/${officer.id}`}  className="list-group-item list-group-item-action" key={officer.id}>
								    	{officer.row_position}.) {officer.name}
									</Link>
								))
							}
						</div>	
					</div>

					<div className="col-md-8">
						<div className="jumbotron">
						  <h1 className="display-3">Executive Officers</h1>
						  <p className="lead">Here are the list of Executive Officers in the PSP Website</p>
						  <hr className="my-4" />
						  <p>To add new officer, click create new. Click any of the officer listed on the left panel to view the details</p>

						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
