import React from 'react';
import Modal from 'react-responsive-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { API_URL } from './utils/apiUrl'
import { WEB_URL } from './utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const MediaModal = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose} center>
      <h2>List of media</h2>
      <p><small><FontAwesomeIcon icon={faInfoCircle} /> Click to copy the URL of the media you want to include in this discussion.</small></p>
      	<hr/>
      	{props.copied && <p className="text-success text-center">Copied!</p>}
		<ul className="list-group">
			{props.medium.map((media,index)=>(
		        <CopyToClipboard key={index} text={`${WEB_URL}/uploads/media/${media}`}
		          onCopy={props.handleCopy}>
					<li className="list-group-item d-flex justify-content-between align-items-center">
						{media}
					</li>
		        </CopyToClipboard>							
			))}
		</ul>
    </Modal>
  )
}

export default MediaModal;