import React, { Component } from 'react';
import './App.css';

import { BrowserRouter, Route, Switch,Redirect } from "react-router-dom";

import Banner from './Banner/Home'

import Video from './Video/Home'


// Discussions
import DiscussionsHome from './Discussions/Home'
import Discussion from './Discussions/Discussion'
import NewDiscussion from './Discussions/NewDiscussion'

// Councils & Committees
import CouncilsHome from './Councils/Home'
import Council from './Councils/Council'
import NewCouncil from './Councils/NewCouncil'
import CommitteesHome from './Committees/Home'
import NewCommittee from './Committees/NewCommittee'
import Committee from './Committees/Committee'

// EO
import EoHome from './ExecutiveOfficers/Home'
import NewEo from './ExecutiveOfficers/NewBoard'
import Eo from './ExecutiveOfficers/Board'

// BOG
import BOGHome from './BoardOfGovernors/Home'
import NewBOG from './BoardOfGovernors/NewBoard'
import BOG from './BoardOfGovernors/Board'

// BOP
import BoardsHome from './Boards/Home'
import NewBoard from './Boards/NewBoard'
import Board from './Boards/Board'

// Regional Chapter
import Regions from './RegionalLeaders/Regions'
import Region from './RegionalLeaders/Region'
import RegionalHome from './RegionalLeaders/Home'
import NewRegion from './RegionalLeaders/NewRegion'
import NewRegionalLeader from './RegionalLeaders/NewBoard'
import RegionalLeader from './RegionalLeaders/Board'

// News
import NewsHome from './News/Home'
import NewNews from './News/NewNews'
import SingleNews from './News/SingleNews'

// Login
import Login from './Login/Home'

// Media
import Media from './Media/Home'

// Forms
import Forms from './Forms/Home'
import NewForm from './Forms/NewForm'
import SingleForm from './Forms/SingleForm'

// Accreditation
import Accreditation from './Accreditation/Home'
import NewAccreditation from './Accreditation/NewAccreditation'
import SingleAccreditation from './Accreditation/SingleAccreditation'

// Lectures
import Lectures from './Lectures/Home'
import NewLecture from './Lectures/NewLecture'
import Lecture from './Lectures/Lecture'
import Questionnaires from './Lectures/Questionnaires'

// Members
import Members from './Members/Home'
import MemberDetailsPrint from './Members/MemberDetailsPrint'
import MemberDetails from './Members/MemberDetails'
import NewMember from './Members/NewMember'

// Landing page
import Home from './Home'

class App extends Component {

	state = {
		isLoggedIn: false
	}

	handleLoginSuccess(str){
		let appState = {
			isLoggedIn:true,
		}
		// save app state with user data in local storage
		localStorage['appState'] = JSON.stringify(appState);		
		localStorage['api_token'] = str.api_token;		
		this.setState({
			isLoggedIn: true
		})   
	}

	handleLogout(){
		let appState = {
		  isLoggedIn: false,
		};
		// save app state with user date in local storage
		localStorage["appState"] = JSON.stringify(appState);
		localStorage.clear();
		this.setState(appState); 
		// window.location.assign("/login");   

	}

	componentDidMount(){
	    let state = localStorage["appState"];
	    if (state) {
	      let AppState = JSON.parse(state);

	      this.setState({ isLoggedIn: AppState.isLoggedIn });
	    }
	}

  render() {
  	const { isLoggedIn} = this.state;
    return (
		<BrowserRouter>
	      <div className="App">
	      		<div className="container py-5">
		      		<Switch>
						<Route exact path="/" render={(props) => (isLoggedIn ? (<Home  handleLogout={this.handleLogout.bind(this)}/>) : (<Redirect to="/login"/>))}/>

						{/*banner*/}							
						<Route exact path='/banner' render={() => (isLoggedIn ? (<Banner />) : (<Redirect to="/login"/>))} />

						{/*video*/}							
						<Route exact path='/video' render={() => (isLoggedIn ? (<Video />) : (<Redirect to="/login"/>))} />

						{/*discussions*/}
						<Route exact path="/discussions" render={() => (isLoggedIn ? (<DiscussionsHome/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/discussions/create" render={(props) => (isLoggedIn ? (<NewDiscussion {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/discussions/:id" render={(props) => (isLoggedIn ? (<Discussion {...props} />) : (<Redirect to="/login"/>))}/>

						{/*committee*/}		
						<Route exact path="/councils" render={() => (isLoggedIn ? (<CouncilsHome/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/councils/create"  render={(props) => (isLoggedIn ? (<NewCouncil {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/councils/:id" render={(props) => (isLoggedIn ? (<Council {...props}/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/councils/:id/createCommittee" render={(props) => (isLoggedIn ? (<NewCommittee {...props}/>) : (<Redirect to="/login"/>))}/>

						<Route exact path="/committees" render={() => (isLoggedIn ? (<CommitteesHome/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/committees/create"  render={(props) => (isLoggedIn ? (<NewCommittee {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/committees/:id" render={(props) => (isLoggedIn ? (<Committee {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*eo*/}		
						<Route exact path="/executiveOfficers" render={() => (isLoggedIn ? (<EoHome/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/executiveOfficers/create"  render={(props) => (isLoggedIn ? (<NewEo {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/executiveOfficers/:id" render={(props) => (isLoggedIn ? (<Eo {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*boardOfGovernors*/}		
						<Route exact path="/boardOfGovernors" render={() => (isLoggedIn ? (<BOGHome/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/boardOfGovernors/create"  render={(props) => (isLoggedIn ? (<NewBOG {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/boardOfGovernors/:id" render={(props) => (isLoggedIn ? (<BOG {...props} />) : (<Redirect to="/login"/>))}/>						
						{/*boards*/}		
						<Route exact path="/boardOfPathology" render={() => (isLoggedIn ? (<BoardsHome/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/boardOfPathology/create"  render={(props) => (isLoggedIn ? (<NewBoard {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/boardOfPathology/:id" render={(props) => (isLoggedIn ? (<Board {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*regionale*/}		
						<Route exact path="/regions" render={() => (isLoggedIn ? (<Regions/>) : (<Redirect to="/login"/>))}/>
						<Route exact path="/regions/create"  render={(props) => (isLoggedIn ? (<NewRegion {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/regions/:id" render={(props) => (isLoggedIn ? (<Region {...props} />) : (<Redirect to="/login"/>))}/>						
						<Route exact path="/regions/:id/createLeader"  render={(props) => (isLoggedIn ? (<NewRegionalLeader {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/regionalLeaders/:id" render={(props) => (isLoggedIn ? (<RegionalLeader {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*news*/}
						<Route exact path="/news/" render={() => (isLoggedIn ? (<NewsHome/>) : (<Redirect to="/login"/>))} />
						<Route exact path="/news/create" render={(props) => (isLoggedIn ? (<NewNews {...props} />) : (<Redirect to="/login"/>))}/>
						<Route exact path="/news/:id" render={(props) => (isLoggedIn ? (<SingleNews {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*media*/}
						<Route exact path='/media' render={(props) => (isLoggedIn ? (<Media  {...props} />) : (<Redirect to="/login"/>))} />

						{/*forms*/}
						<Route exact path='/forms' render={() => (isLoggedIn ? (<Forms />) : (<Redirect to="/login"/>))} />
						<Route exact path='/forms/create' render={(props) => (isLoggedIn ? (<NewForm {...props} />) : (<Redirect to="/login"/>))} />
						<Route exact path="/forms/:id" render={(props) => (isLoggedIn ? (<SingleForm {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*Lectures*/}
						<Route exact path='/Lectures' render={() => (isLoggedIn ? (<Lectures />) : (<Redirect to="/login"/>))} />
						<Route exact path='/Lectures/create' render={(props) => (isLoggedIn ? (<NewLecture {...props} />) : (<Redirect to="/login"/>))} />
						<Route exact path="/Lectures/:id" render={(props) => (isLoggedIn ? (<Lecture {...props} />) : (<Redirect to="/login"/>))}/>						
						<Route exact path="/Lectures/:id/questionnaires" render={(props) => (isLoggedIn ? (<Questionnaires {...props} />) : (<Redirect to="/login"/>))}/>						

						{/*accreditation*/}
						<Route exact path='/accreditation' render={() => (isLoggedIn ? (<Accreditation />) : (<Redirect to="/login"/>))} />
						<Route exact path='/accreditation/create' render={(props) => (isLoggedIn ? (<NewAccreditation {...props} />) : (<Redirect to="/login"/>))} />
						<Route exact path="/accreditation/:id" render={(props) => (isLoggedIn ? (<SingleAccreditation {...props} />) : (<Redirect to="/login"/>))}/>	

						{/*Members*/}
						<Route exact path='/members' render={() => (isLoggedIn ? (<Members />) : (<Redirect to="/login"/>))} />
						<Route exact path='/members/:id/print' render={(props) => (isLoggedIn ? (<MemberDetailsPrint {...props} />) : (<Redirect to="/login"/>))} />
						<Route exact path='/members/:id/show' render={(props) => (isLoggedIn ? (<MemberDetails {...props} />) : (<Redirect to="/login"/>))} />
						<Route exact path='/members/create' render={(props) => (isLoggedIn ? (<NewMember {...props} />) : (<Redirect to="/login"/>))} />
						

						{/*login*/}
						<Route exact path='/login' render={(props) => (!isLoggedIn ? (<Login {...props} handleLoginSuccess={this.handleLoginSuccess.bind(this)} />) : (<Redirect to="/"/>))} />


		      		</Switch>
	      		</div>
	      </div>
		</BrowserRouter>
    );
  }
}

export default App;
