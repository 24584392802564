import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

// HTML Editor
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import HtmlEditor from './HtmlEditor'

import htmlToDraft from 'html-to-draftjs';

// HTML Parser
import ReactHtmlParser from 'react-html-parser';

// import MediaModal from '../MediaModal'
import MediaModal from '../MediaModal'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'



export default class Discussion extends React.Component {

	state ={
		discussion_details: [],
		editMode: false,
		discussion_title: '',
		discussion_content: '',
		editorState: EditorState.createEmpty(),
    	open: false,		
    	medium: [],
    	value: '',
    	copied: false,			
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false, copied: false, });
	};	

	handleCopy(e){
		this.setState({
			copied: true
		})
	}	

	onEditorStateChange: Function = (editorState) => {
		this.setState({
		  editorState,
		});
	};		


	handleEditClick(){
		const blocksFromHtml = htmlToDraft(this.state.discussion_content);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		const editorStates = EditorState.createWithContent(contentState);		
		this.setState({
			editMode: !this.state.editMode,
			editorState: editorStates
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}

	handleDeleteClick(){
		const {history} = this.props
		axios
			.get(API_URL+`/discussions/${this.props.match.params.id}/delete`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push('/discussions')
			})
	}	

	handleUpdateDiscussion(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('title', this.state.discussion_title);
		data.append('content', draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));		
		axios
			.post(API_URL+'/discussions/update'+'?api_token='+localStorage.getItem('api_token') , data)
			.then(({data})=>{
				history.push('/discussions')
			});		
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/discussions/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					discussion_details: data.post,
					discussion_content: data.post.discussion_content,
					discussion_title: data.post.discussion_title
				});
			})

		axios
			.get(API_URL+'/media'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					medium: data.media
				});
			})
	}

	render() {
		const { discussion_details, editMode ,discussion_title ,editorState ,open,medium,copied} = this.state;


		return (
			<div>
				<MediaModal 
					open={open} 
					onClose={this.onCloseModal}
					copied={copied}
					medium={medium}
					handleCopy={this.handleCopy.bind(this)}
				/>				
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/discussions'>Discussions</Link></li>
				  <li className="breadcrumb-item active">{discussion_details.discussion_title}</li>
				</ol>
				{!editMode ?
					<div>
						<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{discussion_details.discussion_title}<span>&nbsp;</span>
							<small>
								<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}> <FontAwesomeIcon icon={faPenNib} /> Edit</button>
							</small>
							<small>
								<button className="btn btn-sm btn-danger" onClick={this.handleDeleteClick.bind(this)}>  Delete</button>
							</small>								
						</h1>
						<a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/discussions/${discussion_details.id}`}>
							<small>
								<FontAwesomeIcon icon={faExternalLinkAlt} /> 
								 Visit Page
							</small>
						</a>
						<hr/>
						<div className="container">
							{ ReactHtmlParser(discussion_details.discussion_content) }
						</div>				
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateDiscussion.bind(this)}>
							<FontAwesomeIcon icon={faInfoCircle} /> Click <button className="btn btn-sm btn-success" type="button" onClick={this.onOpenModal}>Open media</button> to view media.
							<p></p>
							<div className="row">
								<div className="col-12">
									<input 
										type="text" 
										name="discussion_title"
										value={discussion_title} 
										onChange={this.handleInputChange.bind(this)} 
										className="form-control form-control-lg"
									/>
								</div>
							</div>
							<hr/>
								<HtmlEditor editorState={editorState} onEditorStateChange={this.onEditorStateChange} />
								<hr/>
								<button className="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Update</button><span>&nbsp;</span>
								<div className="btn btn-danger" onClick={this.handleCancelEditClick.bind(this)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</div>

						</form>
					</div>
				}
			</div>
		);
	}
}
