import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';

export default class Home extends React.Component {

	state ={
		councils:[]
	}

	componentDidMount(){
		axios
			.get(API_URL+'/councils/all'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				console.log(data)
				this.setState({
					councils: data.councils
				});
			})
	}

	render() {
		const { councils } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Councils</li>
				</ol>			
				<Link to='councils/create' className="btn btn-success">Create new Council</Link>
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{councils &&
								councils.map(council=>(
									<Link to={{ pathname: `councils/${council.id}`, state: { foo: 'bar'} }} className="list-group-item list-group-item-action" key={council.id}>
								    	{council.name}
									</Link>
								))
							}
						</div>	
					</div>


					<div className="col-md-8">
						<div className="jumbotron">
						  <h1 className="display-3">Council and Committees Pages</h1>
						  <p className="lead">Here are the list of committees in the PSP Website</p>
						  <hr className="my-4" />
						  <p>To add new Committees Page, click create new. Click any of the committees listed on the left pannel to view the details</p>

						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
