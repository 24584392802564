import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

export default class MemberDetailsPrint extends React.Component {

	state={
		firstName: '',
		lastName: '',
		email: '',
		member_details: []
	}

	componentDidMount(){
		axios
			.get(`${API_URL}index.php/Membersportal/getMember/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					member_details: data.query[0],
				});
			})		
		setTimeout(()=>{window.print();},700);
	}


	render() {
		const {member_details} = this.state;
		return (
			<div style={{marginLeft: '100px', marginRight: '100px'}}>
				<div className="row justify-content-center">
					<div className="col-md-7">
						<h5>Dear {member_details.firstName+" "+member_details.lastName},</h5>
						<p>These are your login credentials to PSP membership portal.</p>
						<br/>
						<table className="table table-striped table-inverse table-hover" >
							<tbody>
								<tr>
									<td>Name</td>
									<td>{member_details.firstName+" "+member_details.lastName}</td>
								</tr>
								<tr>
									<td>Email address</td>
									<td>{member_details.emailAddress ? member_details.emailAddress : 'N/A'}</td>
								</tr>
								<tr>
									<td>Username</td>
									<td>{member_details.username}</td>																		
								</tr>
								<tr>
									<td>Password</td>
									<td>123</td>									
								</tr>
							</tbody>
						</table>			
						<hr/>
						<p>To log-in, go to <strong>{`${API_URL}index.php/membersportal/login`}</strong> and enter your username and password.</p>
						<p>After log in, change your password. Please update your membership info and make the necessary corrections. An updated membership information is a requirement prior to release of the certificate of attendance.
Thank you.
</p>		
						<button className="btn btn-success btn-round btn-sm d-print-none" onClick={()=>{window.print()}}>Print</button>
						<button className="btn btn-danger btn-round btn-sm d-print-none float-right" onClick={()=>{this.props.history.push('/members')}}>Back to list</button>
					</div>
				</div>
			</div>
		);
	}
}
