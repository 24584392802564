import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default class Board extends React.Component {
	state = {
		board_details: [],
		name: '',
		position:'',
		row_position:'',
		term:'',
		file_path: '',
		editMode: false,
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/leadership/officer/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					board_details: data.officer,
					name: data.officer.name,
					position: data.officer.position,
					row_position: data.officer.row_position,
					term: data.officer.term,
					file_path: data.officer.display_picture
				});
			})
	}

	handleFileChange(e){
		let file = e.target.files;
		this.state.file_path = '';
		this.setState({
			file_path: e.target.files[0]
		})
	}

	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
			file_path: ''
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}

	handleDeleteClick(){
		const {history} = this.props
		axios
			.get(API_URL+`/leadership/officer/${this.props.match.params.id}/delete`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push('/executiveOfficers')
			})
	}


	handleUpdateBop(e){
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }  		
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('name', this.state.name);
		data.append('position', this.state.position);
		data.append('row_position', this.state.row_position);
		data.append('term', this.state.term);
		data.append('image', this.state.file_path);
		axios
			.post(API_URL+'/leadership/updateOfficer'+'?api_token='+localStorage.getItem('api_token') , data,config)
			.then(({data})=>{
				history.push('/executiveOfficers')
			});		
	}		

	render() {
		const { name, editMode ,position ,file_path, board_details, row_position, term} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/executiveOfficers'>Executive Officers</Link></li>
				  <li className="breadcrumb-item active">{board_details.name}</li>
				</ol>
				{!editMode ?
					<div>
						<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{board_details.name}<span>&nbsp;</span>
							<small>
								<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}>  Edit</button>
							</small>
							<small>
								<button className="btn btn-sm btn-danger" onClick={this.handleDeleteClick.bind(this)}>  Delete</button>
							</small>							
						</h1>
						<a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/leadership/executiveofficers`}>
							<small>
								 Visit Page
							</small>
						</a>
						<p></p>
						<table className="table table-hover">
							<tbody>
								<tr>
									<td>Name:</td>
									<td>{name}</td>
								</tr>
								<tr>
									<td>Position:</td>
									<td>{position}</td>
								</tr>
								<tr>
									<td>Row position:</td>
									<td>{row_position}</td>
								</tr>	
								<tr>
									<td>Term:</td>
									<td>{term}</td>
								</tr>															
								<tr>
									<td>Display Picture: <small className="text-danger"> *Updating image may have delay, please click <a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/leadership/executiveofficers`}>here</a> to view actual page.</small></td>
									<td><img src={WEB_URL+file_path} alt="" style={{width:200}} /></td>
								</tr>								
							</tbody>
						</table>
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateBop.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
						  <fieldset>
						    <legend>Edit Board of Pathology</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Name</label>
						      <input 
						      	name="name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={name}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>
						    <div className="form-group">
						      <label>Position</label>
						      <input 
						      	name="position"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={position}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>
						    <div className="form-group">
						      <label>Row Position</label>
						      <input 
						      	name="row_position"
						      	type="number" 
						      	min={1}						      	
						      	className="form-control form-control-lg" 
						      	value={row_position}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>	
						    <div className="form-group">
						      <label>Term</label>
						      <input 
						      	name="term"
						      	type="text" 
						      	className="form-control form-control-lg" 

						      	value={term}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>						    					    
		                  <div className="form-group files">
		                    <label>Upload display picture </label>
		                    <input 
		                    	type="file" 
		                    	name="display_picture" 
		                    	size="20" 
		                    	accept=".jpg, .png" 
		                    	className="form-control form-control-lg"
		                    	onChange={this.handleFileChange.bind(this)}
		                    />
		                  </div>						    
						    <div className="form-group">
								<button className="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Update</button><span>&nbsp;</span>
								<div className="btn btn-danger" onClick={this.handleCancelEditClick.bind(this)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</div>
						    </div>	
						  </fieldset>
						</form>
					</div>
					}
			</div>
		);
	}
}
