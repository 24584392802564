import React from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

export default class NewDiscussion extends React.Component {

	state={
		name:'',
		position:'',
		row_position:'',
		file_path: '',
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	handleFileChange(e){
		let file = e.target.files;
		this.state.file_path = '';
		this.setState({
			file_path: e.target.files[0]
		})
	}

	handleCreateCommittee(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('council_id', this.props.match.params.id);
		data.append('name', this.state.name);
		data.append('position', this.state.position);
		data.append('row_position', this.state.row_position);
		data.append('image', this.state.file_path);
		axios
			.post(API_URL+'/committees/saveNewCommittees'+'?api_token='+localStorage.getItem('api_token') , data,  {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  }).then(({data})=>{
				history.push('/councils/'+this.props.match.params.id)
			});
	}

	render() {
		const { name,position,row_position } = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/councils'>Councils</Link></li>
				  <li className="breadcrumb-item active">Create new Committee</li>
				</ol>				
				<div className="row justify-content-center">
					<div className="col-md-9">
						<form onSubmit={this.handleCreateCommittee.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
						  <fieldset>
						    <legend>New Committee</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Name</label>
						      <input 
						      	name="name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Enter name" 
						      	value={name}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>
						    <div className="form-group">
						      <label>Position</label>
						      <input 
						      	name="position"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="positionHelp" 
						      	placeholder="Enter position" 
						      	value={position}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>
						    <div className="form-group">
						      <label>Ordering Position</label>
						      <input 
						      	name="row_position"
						      	type="number" 
						      	min={1}
						      	className="form-control form-control-lg" 
						      	aria-describedby="positionHelp" 
						      	placeholder="Enter ordering position" 
						      	value={row_position}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>						    
		                  <div className="form-group files">
		                    <label>Upload display picture </label>
		                    <input 
		                    	type="file" 
		                    	size="20" 
		                    	accept=".jpg, .png" 
		                    	className="form-control form-control-lg"
		                    	onChange={this.handleFileChange.bind(this)}
		                    />
		                  </div>						    
						    <div className="form-group">
								<button className="btn btn-success" type="submit">Save</button>
						    </div>	

						  </fieldset>

						</form>			
					</div>
				</div>
			</div>
		);
	}
}
