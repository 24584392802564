import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl';

export default class NewForm extends React.Component {
  state = {
    title: '',
    file_path: null,
  };

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFileChange(e) {
    let file = e.target.files;
    this.setState({
      file_path: file[0],
    });
  }

  handleUploadForm(e) {
    const { history } = this.props;
    e.preventDefault();
    let data = new FormData();
    data.append('title', this.state.title);
    data.append('file_path', this.state.file_path, this.state.file_path.name);
    axios
      .post(
        API_URL +
          '/downloadables' +
          '?api_token=' +
          localStorage.getItem('api_token'),
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(({ data }) => {
        history.push('/forms');
      });
  }

  render() {
    const { title } = this.state;
    return (
      <div>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <Link to='/'>Dashboard</Link>
          </li>
          <li className='breadcrumb-item'>
            <Link to='/forms'>Forms</Link>
          </li>
          <li className='breadcrumb-item active'>Upload new Form</li>
        </ol>
        <div className='row justify-content-center'>
          <div className='col-md-9'>
            <form
              onSubmit={this.handleUploadForm.bind(this)}
              encType='multipart/form-data'
              method='post'
              accept-charset='utf-8'
            >
              <fieldset>
                <legend>New Form</legend>

                <p />
                <div className='form-group'>
                  <label>Form Title</label>
                  <input
                    name='title'
                    type='text'
                    className='form-control form-control-lg'
                    aria-describedby='nameHelp'
                    placeholder='Enter form title'
                    value={title}
                    onChange={this.handleInputChange.bind(this)}
                    required
                  />
                </div>

                <div className='form-group files'>
                  <label>Upload form file </label>
                  <input
                    type='file'
                    size='20'
                    accept='.pdf, .docx, .doc'
                    className='form-control form-control-lg'
                    onChange={this.handleFileChange.bind(this)}
                    required
                  />
                </div>
                <div className='form-group'>
                  <button className='btn btn-success' type='submit'>
                    Save
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
