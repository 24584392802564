import React from 'react';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'

import { Link } from 'react-router-dom';
import './upload.css'

export default class Home extends React.Component {

	state ={
		medium:[],
		title:'',
		file_path: null,
	}

	handleImageUrlSelected(e){
		alert(e.target.src)
	}



	componentDidMount(){
		axios
			.get(API_URL+'/media'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					medium: data.media
				});
			})
	}

	handleFileChange(e){
		let file = e.target.files;
		console.log(e.target.files[0])
		this.setState({
			file_path: e.target.files[0]
		})
	}	

	handleUploadForm(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('title', this.state.title);
		data.append('image', this.state.file_path);
		axios
			.post(API_URL+'/media/save'+'?api_token='+localStorage.getItem('api_token') , data,  {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  }).then(({data})=>{
				window.location.href="/"
			});
	}	

	render() {
		const { medium } = this.state
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Media</li>
				</ol>			
				<p></p>
				<div className="row">
					<div className="col-md-4">
						<div className="list-group">
							{medium.map((media,index)=>(
								<p to={`media/${index}`} className="list-group-item list-group-item-action" key={index}>
							    	{media}
								</p>
							))}

						</div>	
					</div>

					<div className="col-md-8">
						<div className="jumbotron">
							  <h3 className="display-3">Upload New Media</h3>
							  <p>Upload media you want to include to discussion, news ,etc..</p>
						      <form onSubmit={this.handleUploadForm.bind(this)} encType="multipart/form-data" method="post" accept-charset="utf-8">
					              <div className="form-group files">
					                <input 
				                    	type="file" 
				                    	size="20" 
				                    	accept=".png, .jpg" 
				                    	className="form-control form-control-lg"
				                    	onChange={this.handleFileChange.bind(this)}
				                    	required 					                	
					                />
					              </div>

				                  <div className="form-group">
				                    <input type="submit" value="Upload" />
				                  </div>				              
					            
					          </form>
						</div>						
					</div>
				</div>			
			</div>
		);
	}
}
