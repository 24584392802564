import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default class Board extends React.Component {
	state = {
		board_details: [],
		name: '',
		select_region:'',
		region:[],
		regions:[],
		position:'',
		row_position:'',
		file_path: '',
		editMode: false,
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/leadership/regionalchapter/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					board_details: data.post,
					name: data.post.name,
					position: data.post.position,
					select_region: data.post.region_id,
					regions: data.regions,
					row_position: data.post.row_position,
					file_path: data.post.display_picture
				});
				let fregions =this.state.regions 
				for(let i in fregions){
					if(fregions[i].id == this.state.board_details.region_id){
						this.setState({
							region: fregions[i]
						})
					}
				}			
			})
				

				
		
	}

	handleFileChange(e){
		let file = e.target.files;
		this.state.file_path = '';
		this.setState({
			file_path: e.target.files[0]
		})
		console.log(this.state.file_path)
	}

	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
			file_path: ''
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}

	handleDeleteClick(){
		const {history} = this.props
		axios
			.get(API_URL+`/leadership/regional-leader/${this.props.match.params.id}/delete`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push(`/regions/${this.state.board_details.region_id}/`)
			})
	}

	handleUpdateBop(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('name', this.state.name);
		// data.append('region', this.state.region);
		data.append('position', this.state.position);
		data.append('region', this.state.select_region);
		data.append('row_position', this.state.row_position);
		data.append('image', this.state.file_path);
		axios
			.post(API_URL+'/leadership/updateRegionalLeader'+'?api_token='+localStorage.getItem('api_token') , data,{
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  })
			.then(({data})=>{
				history.push(`/regions/${this.state.board_details.region_id}/`)
			});		
	}		



	render() {
		const { name, editMode ,region,position ,file_path, board_details, select_region,row_position,regions} = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/regions'>Regional Chapter</Link></li>
				  <li className="breadcrumb-item active">{board_details.name}</li>
				</ol>
				{!editMode ?
					<div>
						<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{board_details.name}<span>&nbsp;</span>
							<small>
								<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}>  Edit</button>
							</small>
							<small>
								<button className="btn btn-sm btn-danger" onClick={this.handleDeleteClick.bind(this)}>  Delete</button>
							</small>							
						</h1>
						<a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/leadership/regionalchapters`}>
							<small>
								 Visit Page
							</small>
						</a>
						<p></p>
						<table className="table table-hover">
							<tbody>
								<tr>
									<td>Name:</td>
									<td>{name}</td>
								</tr>
								<tr>
									<td>Position:</td>
									<td>{position}</td>
								</tr>	
								<tr>
									<td>Region:</td>
									<td>Region {region.number} - {region.name}</td>
								</tr>															
								<tr>
									<td>Ordering position:</td>
									<td>{row_position}</td>
								</tr>								
								<tr>
									<td>Display Picture: <small className="text-danger"> *Updating image may have delay, please click <a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/leadership/regionalchapters`}>here</a> to view actual page.</small></td>
									<td><img src={WEB_URL+file_path} alt="" style={{width:200}} /></td>
								</tr>								
							</tbody>
						</table>
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateBop.bind(this)} encType="multipart/form-data" method="post" acceptCharset="utf-8">
						  <fieldset>
						    <legend>Edit Regional Leader</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Name</label>
						      <input 
						      	name="name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={name}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>					    						    
						    <div className="form-group">
						      <label>Position</label>
						      <input 
						      	name="position"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={position}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>
						    <div className="form-group">
						      <label>Ordering Position</label>
						      <input 
						      	name="row_position"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={row_position}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>						    
						    <div className="form-group">
						      <label>Region</label>
								<select value={select_region} name="select_region" className="form-control form-control-lg" onChange={this.handleInputChange.bind(this)}>
									{regions.map(region =>(
									  <option value={region.id} key={region.id}>Region {region.number} - {region.name}</option>
									))}
								</select>						      
						    </div>						    
		                  <div className="form-group files">
		                    <label>Upload display picture </label>
		                    <input 
		                    	type="file" 
		                    	name="display_picture" 
		                    	size="20" 
		                    	accept=".jpg" 
		                    	className="form-control form-control-lg"
		                    	onChange={this.handleFileChange.bind(this)}
		                    />
		                  </div>						    
						    <div className="form-group">
								<button className="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Update</button><span>&nbsp;</span>
								<div className="btn btn-danger"  onClick={this.handleCancelEditClick.bind(this)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</div>
						    </div>	
						  </fieldset>
						</form>
					</div>
					}
			</div>
		);
	}
}
