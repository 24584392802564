import React from 'react';

const ErrorAlert = (props) => {
  return (
    <div>
		<div className="alert alert-dismissible alert-danger">
		  <strong>Oh snap!</strong> {props.message}
		</div>    	
    </div>
  )
}

export default ErrorAlert;