import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import MediaModal from '../MediaModal'

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

export default class Board extends React.Component {
	state = {
		banner_details: [],
		url: '',
		editMode: false,
    	copied: false,				
       	open: false,		
    	medium: [],
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/banner/banner_details`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{

				this.setState({
					banner_details: data.banner_details,
					// name: data.post.name,
					// position: data.post.position,
					// file_path: data.post.display_picture
				});
			})
		axios
			.get(API_URL+'/media'+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					medium: data.media
				});
			})			
	}

	// handleFileChange(e){
	// 	let file = e.target.files;
	// 	this.state.file_path = '';
	// 	this.setState({
	// 		file_path: e.target.files[0]
	// 	})
	// }

	async handleCopy(e){
		this.setState({
			copied: true,
			url: ''
		})
	}	

	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
			url: this.state.banner_details.url
		});
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false, copied: false, });
	};		

	// handleCancelEditClick(){
	// 	this.setState({
	// 		editMode: !this.state.editMode,
	// 	});		
	// }


	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}



	handleUpdateBanner(e){
		e.preventDefault();
		let data = new FormData();
		data.append('url', this.state.url);
		axios
			.post(API_URL+'/banner/update'+'?api_token='+localStorage.getItem('api_token') , data)
			.then(({data})=>{
				window.location.href="/Banner"
			});		
	}		

	render() {
		const { open, editMode ,copied ,url, banner_details,medium} = this.state;
		return (
			<div>
				<MediaModal 
					open={open} 
					onClose={this.onCloseModal}
					copied={copied}
					medium={medium}
					handleCopy={this.handleCopy.bind(this)}
				/>			
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item active">Promoting Banner</li>
				</ol>
				{!editMode ?
					<div>
						<h1>Active Banner</h1>
						<small>
							<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}>  Edit</button>
						</small>						
						<hr/>
						<img src={banner_details.url} className="img-fluid" alt=""/>
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateBanner.bind(this)}>
						  <fieldset>
						    <legend>Update Banner</legend>

						    <FontAwesomeIcon icon={faInfoCircle} /> Click <button className="btn btn-sm btn-success" type="button" onClick={this.onOpenModal}>Open media</button> to view media.
						    <p></p>
						    <div className="form-group">
						      <label>Title</label>
						      <input 
						      	name="url"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="titleHelp" 
						      	placeholder="Enter Title" 
						      	value={url}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>

						    <div className="form-group">
								<button className="btn btn-success" type="submit">Save</button>
								<button className="btn btn-danger" onClick={()=>{this.setState({editMode: false})}}>  Cancel</button>
						    </div>	

						  </fieldset>

						</form>
					</div>
					}
			</div>
		);
	}
}
