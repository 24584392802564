import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { API_URL } from '../utils/apiUrl'
import { WEB_URL } from '../utils/webUrl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default class SingleForm extends React.Component {
	state = {
		accredited_details: [],
		hospital_name:'',
		year_accredited: '',
		year:'',
		editMode: false,
	}

	componentDidMount(){
		axios
			.get(`${API_URL}/accreditation/${this.props.match.params.id}`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data}) =>{
				this.setState({
					accredited_details: data.post,
					hospital_name: data.post.hospital_name,
					year_accredited: data.post.year_accredited,
					year: data.post.year,
				});
			})
	}


	handleEditClick(){
		this.setState({
			editMode: !this.state.editMode,
		});
	}

	handleCancelEditClick(){
		this.setState({
			editMode: !this.state.editMode
		});		
	}

	handleInputChange(e){
		this.setState({
			[e.target.name] : e.target.value
		})		
	}

	handleDeleteClick(){
		const {history} = this.props
		axios
			.get(API_URL+`/accreditation/${this.props.match.params.id}/delete`+'?api_token='+localStorage.getItem('api_token'))
			.then(({data})=>{
				history.push('/accreditation')
			})
	}	


	handleUpdateAccredited(e){
		const {history} = this.props
		e.preventDefault();
		let data = new FormData();
		data.append('id', this.props.match.params.id);
		data.append('hospital_name', this.state.hospital_name);
		data.append('year_accredited', this.state.year_accredited);
		data.append('year', this.state.year);
		axios
			.post(API_URL+'/accreditation/updateAccredited'+'?api_token='+localStorage.getItem('api_token') , data)
			.then(({data})=>{
				history.push('/accreditation')
			});		
	}		

	render() {
		const { hospital_name,year_accredited, year, editMode,accredited_details } = this.state;
		return (
			<div>
				<ol className="breadcrumb">
				  <li className="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
				  <li className="breadcrumb-item"><Link to='/accreditation'>Accreditation</Link></li>
				  <li className="breadcrumb-item active">{accredited_details.hospital_name}</li>
				</ol>
				{!editMode ?
					<div>
						<h1 className="titlehead" style={{color: '#327d16', fontSize: 20,  paddingTop: 30}}>{accredited_details.hospital_name}<span>&nbsp;</span>
							<small>
								<button className="btn btn-sm btn-primary" onClick={this.handleEditClick.bind(this)}>  Edit</button>
							</small>
							<small>
								<button className="btn btn-sm btn-danger" onClick={this.handleDeleteClick.bind(this)}>  Delete</button>
							</small>								
						</h1>
						<a target="_blank" rel="noopener noreferrer" href={`${WEB_URL}/accreditation`}>
							<small>
								 Visit Page
							</small>
						</a>
						<p></p>
						<table className="table table-hover">
							<tbody>
								<tr>
									<td>Hospital Name:</td>
									<td>{hospital_name}</td>
								</tr>
								<tr>
									<td>Year Accredited:</td>
									<td>{year_accredited}</td>
								</tr>
								<tr>
									<td>Year:</td>
									<td>{year}</td>
								</tr>																
							</tbody>
						</table>
					</div>
					:
					<div>
						<form onSubmit={this.handleUpdateAccredited.bind(this)}method="post">
						  <fieldset>
						    <legend>Edit Accredited</legend>

						    <p></p>
						    <div className="form-group">
						      <label>Hospital Name</label>
						      <input 
						      	name="hospital_name"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	value={hospital_name}
						      	onChange={this.handleInputChange.bind(this)}
						      />
						    </div>
						    <div className="form-group">
						      <label>Year accredited</label>
						      <input 
						      	name="year_accredited"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Ex. 2018-2019" 
						      	value={year_accredited}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>						    

						    <div className="form-group">
						      <label>Year</label>
						      <input 
						      	name="year"
						      	type="text" 
						      	className="form-control form-control-lg" 
						      	aria-describedby="nameHelp" 
						      	placeholder="Ex. 2019" 
						      	value={year}
						      	onChange={this.handleInputChange.bind(this)}
						      	required
						      />
						    </div>						    
						    
						    <div className="form-group">
								<button className="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Update</button><span>&nbsp;</span>
								<div className="btn btn-danger" onClick={this.handleCancelEditClick.bind(this)}><FontAwesomeIcon icon={faTimesCircle} /> Cancel</div>
						    </div>	
						  </fieldset>
						</form>
					</div>
					}
			</div>
		);
	}
}
